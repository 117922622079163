const Player = require('./player');
const RTCatError = require('../error');
const Utils = require('../utils/utils');
const Detect = require('../platform/detect');
const StreamWrapper = require('./streamwrapper');

const VIDEO_DEFAULT_WIDTH = 300;
const VIDEO_DEFAULT_HEIGHT = 300;

/**
 * 流的抽象类
 *
 * @extends {StreamWrapper}
 */
class AbstractStream extends StreamWrapper {


  constructor({
    mediaStream = null,
    local = true
  } = {}) {
    super(mediaStream);

    this._id = Utils.generateUUID();
    this._local = local;
    this._played = false;
    this._player = null;
    this._container = null;
    this._state = null;
    this._stateText = null;

    this._streamCopys = [];
    this._isOtherSidesAudioEnabled = true;
    this._isOtherSidesVideoEnabled = true;
  }


  /**
   * 播放流,并为流生成一个播放器,播放器为`<video>`,可以通过 {@link AbstractStream#get player} 方法获得播放器。
   * @param {external:String} [container] 流将会在给定的 `container` 的 `div` 中播放流,其中 `container` 为 `css 选择器`,目前支持`id选择器`和`类选择器`。
   * 默认使用 `id选择器` , 比如 `container` 为 `testDiv`,那么流将会在`id`为`testDiv`的`div`中播放流 。如果不指定`container`,默认在 `id` 为 `local` 的 `div` 中播放流。
   * @param {external:Object} [options]
   * @param {external:Object} [options.size={width:300,height:300}] 播放器大小
   * @param {external:String} [options.poster=实时猫logo] poster为当播放器初始化之后无视频时,播放器显示的图像,`poster`目前仅支持链接的形式。
   *
   */
  play(container, options) {

    if (!this._played) {
      this._played = true;

      let {
        size,
        poster
      } = options || {
        size: {
          width: VIDEO_DEFAULT_WIDTH,
          height: VIDEO_DEFAULT_HEIGHT
        }
      };

      let _container;

      container = container || 'local';

      if (typeof container === 'string') {

        if (container.indexOf("data-") === -1 &&
          container.indexOf("#") === -1 &&
          container.indexOf(".") === -1) {

          _container = '#' + container;

        }

      } else if (container instanceof HTMLElement &&
        container.tagName.toLowerCase() === 'video') {

        _container = container;

      } else {

        throw (new Error('container must be a video element or an element selector'));

      }

      this._container = _container;
      this._player = new Player(_container, this._local, size);

      if (!Detect.isSafari()) {
        // if (!poster) poster = posterAdapter(size);
        // this._player.poster(poster);
      } else {
        // this._player.element.setAttribute('controls','controls');
        this._player.element.setAttribute('playsinline', 'playsinline');
        // this._player.element.removeAttribute('autoplay');
      }

      this._player.play(this.mediaStream);
    } else {
      this.emit('error', new RTCatError({
        code: RTCatError.ErrorCode.STREAM_ALREADY_PLAYED
      }));
    }

  }

  unplay() {
    if (this._played) {
      this._played = false;
      this._player.stop();
    }
  }

  close() {

    if (this._volumeMeter) {
      this._volumeMeter.shutdown();
      delete this._volumeMeter;
    }

    this.unplay();

    super.stop();

    this._streamCopys.forEach(stream => stream.stop());
    this._streamCopys = [];
  }

  /**
   * 回收播放器并且销毁流。
   */
  stop() {

    if (this._stateText) {
      this._stateText.remove();
      delete this._stateText;
    }

    this.close();
  }

  /**
   * 重新设置播放器大小
   * @param {external:Object} size
   * @param {external:Integer} [size.width=300] 重新设置后播放器的宽度
   * @param {external:Integer} [size.height=300] 重新设置后播放器的高度
   */
  resize(size) {
    this._player.setSize(size);
  }

  /**
   * 获得流的播放器对象
   * @returns {external:video}
   */
  get player() {
    return this._player;
  }

  /**
   * stream
   * @returns {MediaStream}
   */
  get stream() {
    return this.mediaStream;
  }

  /**
   * 获得 MediaStream 对象 (WebRTC)
   * @returns {MediaStream}
   */
  getStream() {
    return this.stream;
  }

  disableVideo(options) {
    this.changeMedia('video', false, options);
  }

  disableAudio(options) {
    this.changeMedia('audio', false, options);
  }

  enableVideo(options) {
    this.changeMedia('video', true, options);
  }

  enableAudio(options) {
    this.changeMedia('audio', true, options);
  }

  getStreamCloneWrapper() {
    let sc = this.clone();
    sc.setVideoEnabled(this._isOtherSidesVideoEnabled);
    sc.setAudioEnabled(this._isOtherSidesAudioEnabled);
    this._streamCopys.push(sc);
    return sc;
  }

  changeMedia(type, isEnabled, options) {
    let self = true;
    let others = true;
    // console.log("options",options);
    if (options) {
      if (options.self === false) {
        self = false;
      }
      if (options.others === false) {
        others = false;
      }
    }

    if (self) {
      if (type == 'audio') {
        this.setAudioEnabled(isEnabled);
      } else if (type == 'video') {
        this.setVideoEnabled(isEnabled);
      }
    }

    if (others) {
      this.changeOtherSidesMedia(type, isEnabled);
    }

  }

  changeOtherSidesMedia(type, isEnabled) {
    if (type == 'audio') {
      this._isOtherSidesAudioEnabled = isEnabled;
    } else if (type == 'video') {
      this._isOtherSidesVideoEnabled = isEnabled;
    }

    for (let i in this._streamCopys) {
      if (this._streamCopys.hasOwnProperty(i)) {
        if (this._streamCopys[i]) {
          // console.log(type,this._streamCopys[i]);
          if (type == 'audio') {
            this._streamCopys[i].setAudioEnabled(isEnabled);
          } else if (type == 'video') {
            this._streamCopys[i].setVideoEnabled(isEnabled);
          }
        }
      }
    }
  }

  /**
   * 获得流的编号
   * @returns {external:String}
   */
  get id() {
    return this._id;
  }

  /**
   * 获得流的编号
   * @returns {external:String}
   */
  getId() {
    return this._id;
  }

  load(plugin) {
    //todo check plugin
    plugin.source = this;
    return this;
  }

  addController() {
    this._player.element.setAttribute('controls', 'controls');
  }
}

AbstractStream.Type = {
  MediaDevice: 1,
  Screen: 2,
  HtmlElement: 3,
  File: 4,
  SystemAudio: 5,
  Custom: 6
}

module.exports = AbstractStream;

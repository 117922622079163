const RTCatLog = require('../utils/log')
const utils = require('../utils/utils');
const EventEmitter = require('events').EventEmitter;

const VIDEO_MUTE_TRACK_DELAY = 3000;

/**
 * wrap MediaStream
 * MediaStream : https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API
 */
class StreamWrapper extends EventEmitter {

  constructor(mediaStream) {
    super();

    this._id = utils.uuid();

    this.mediaStream = null;

    this.videoMuteTimer = null;

    if (mediaStream) {
      this.mediaStream = mediaStream;
    }

    this.onaudioended = () => {
      RTCatLog.I('stream audio end');
    };

    this.onvideoended = () => {
      RTCatLog.I('stream video end');
    };

    this.onaudiomute = () => {
      RTCatLog.D('stream audio mute');
    }

    this.onaudiounmute = () => {
      RTCatLog.D('stream audio unmute');
    }

    this.onvideomute = () => {
      RTCatLog.D('stream video mute');
    }

    this.onvideounmute = () => {
      RTCatLog.D('stream video unmute');
    }
  }

  createVolumeMeter() {
    if (this.hasAudio()) {
      RTCatLog.D('stream create audio volume meter');
      let audioContext = utils.getAudioContext();
      let mediaStreamSource = audioContext.createMediaStreamSource(this.mediaStream);
      this._volumeMeter = utils.createAudioMeter(audioContext);
      mediaStreamSource.connect(this._volumeMeter);
    }
  }

  get sid() {
    return this._id;
  }
  /**
   * getAudioLevel
   * @returns {*|number|ConstrainDoubleRange|DoubleRange|boolean}
   */
  getAudioLevel() {
    if (this._volumeMeter) {
      return this._volumeMeter.volume;
    }
  }

  set mediaStream(mediaStream) {

    if (this._mediaStream) {
      if (this.hasAudio()) {
        this.audioTrack.onended = null;
      }
      if (this.hasVideo()) {
        this.videoTrack.onended = null;
      }
    }

    this._mediaStream = mediaStream;

    if (this.audioTrack != null) {
      this.audioTrack.onended = () => {
        if (this.onaudioended && typeof this.onaudioended === 'function') {
          this.onaudioended();
        }
        this.emit('audioended');
      };
      this.audioTrack.onmute = () => {
        if (this.onaudiomute && typeof this.onaudiomute === 'function') {
          this.onaudiomute();
        }
        this.emit('audiomute');
      };
      this.audioTrack.onunmute = () => {
        if (this.onaudiounmute && typeof this.onaudiounmute === 'function') {
          this.onaudiounmute();
        }
        this.emit('audiounmute');
      };

      this.createVolumeMeter();
    }

    if (this.videoTrack !== null) {
      this.videoTrack.onended = () => {
        if (this.onvideoended && typeof this.onvideoended === 'function') {
          this.onvideoended();
        }
        this.emit('videoended');
      };

      this.videoTrack.onmute = () => {
        this.videoMuteTimer = setTimeout(() => {
          if (!this.videoTrack || !this.videoTrack.muted) {
            return;
          }

          if (this.onvideomute && typeof this.onvideomute === 'function') {
            this.onvideomute();
          }
          this.emit('videomute');

          this.videoMuteTimer = null;
        }, VIDEO_MUTE_TRACK_DELAY);
      };

      this.videoTrack.onunmute = () => {
        clearTimeout(this.videoMuteTimer);
        this.videoMuteTimer = null;
        if (this.onvideounmute && typeof this.onvideounmute === 'function') {
          this.onvideounmute();
        }
        this.emit('videounmute');
      };
    }

    this.emit('replaceStream', mediaStream);
  }

  get mediaStream() {
    return this._mediaStream;
  }

  hasVideo() {
    return this.mediaStream && this.mediaStream.getVideoTracks() &&
      this.mediaStream.getVideoTracks().length > 0;
  }

  hasAudio() {
    return this.mediaStream && this.mediaStream.getAudioTracks() &&
      this.mediaStream.getAudioTracks().length > 0;
  }

  get videoTrack() {
    return this.hasVideo() ? this.mediaStream.getVideoTracks()[0] : null;
  }

  get audioTrack() {
    return this.hasAudio() ? this.mediaStream.getAudioTracks()[0] : null;
  }

  set videoTrack(vt) {
    if (this.videoTrack === vt) {
      return;
    }

    if (vt) {
      if (this.hasVideo()) {
        this.videoTrack.onended = null;
        this.mediaStream.removeTrack(this.mediaStream.getVideoTracks()[0]);
      }
      this.mediaStream.addTrack(vt);
      vt.onended = () => {
        if (this.onvideoended && typeof this.onvideoended === 'function') {
          this.onvideoended();
        }
        this.emit('videoended');
      };
      vt.onmute = () => {
        this.videoMuteTimer = setTimeout(() => {
          if (!this.videoTrack || this.videoTrack !== vt || !this.videoTrack.muted) {
            return;
          }
          if (this.onvideomute && typeof this.onvideomute === 'function') {
            this.onvideomute();
          }
          this.emit('videomute');
          this.videoMuteTimer = null;
        }, VIDEO_MUTE_TRACK_DELAY);
      };
      vt.onunmute = () => {
        clearTimeout(this.videoMuteTimer);
        this.videoMuteTimer = null;
        if (this.onvideounmute && typeof this.onvideounmute === 'function') {
          this.onvideounmute();
        }
        this.emit('videounmute');
      };
    } else if (this.hasVideo()) {
      this.videoTrack.onended = null;
      this.mediaStream.removeTrack(this.mediaStream.getVideoTracks()[0]);
    }
    this.emit('replaceTrack', vt, 'video');
  }

  set audioTrack(at) {
    if (this.audioTrack === at) {
      return;
    }

    if (at) {
      if (this.hasAudio()) {
        this.audioTrack.onended = null;
        this.mediaStream.removeTrack(this.mediaStream.getAudioTracks()[0]);
      }
      this.mediaStream.addTrack(at);
      this.createVolumeMeter();
      at.onended = () => {
        if (this.onaudioended && typeof this.onaudioended === 'function') {
          this.onaudioended();
        }
        this.emit('audioended');
      };
      at.onmute = () => {
        if (this.onaudiomute && typeof this.onaudiomute === 'function') {
          this.onaudiomute();
        }
        this.emit('audiomute');
      };
      at.onunmute = () => {
        if (this.onaudiounmute && typeof this.onaudiounmute === 'function') {
          this.onaudiounmute();
        }
        this.emit('audiounmute');
      };
    } else if (this.hasAudio()) {
      this.audioTrack.onended = null;
      this.mediaStream.removeTrack(this.mediaStream.getAudioTracks()[0]);
    }

    this.emit('replaceTrack', at, 'audio');
  }

  stop() {
    if (this.mediaStream.stop) {
      this.mediaStream.stop();
    } else {
      this.stopVideo();
      this.stopAudio();
    }
  }

  stopVideo() {
    if (this.videoTrack) {
      this.videoTrack.stop();
      this.mediaStream.removeTrack(this.videoTrack)
    }
  }

  stopAudio() {
    if (this.audioTrack) {
      this.audioTrack.stop();
      this.mediaStream.removeTrack(this.audioTrack)
    }
  }

  clone() {
    let mediaStream = new MediaStream();
    if (this.videoTrack) {
      mediaStream.addTrack(this.videoTrack.clone())
    }
    if (this.audioTrack) {
      mediaStream.addTrack(this.audioTrack.clone())
    }
    return new StreamWrapper(mediaStream)
  }

  setVideoEnabled(isEnabled) {
    if (this.videoTrack) {
      this.videoTrack.enabled = isEnabled;
    }
  }

  setAudioEnabled(isEnabled) {
    if (this.audioTrack) {
      this.audioTrack.enabled = isEnabled
    }
  }

  isVideoEnabled() {
    return this.videoTrack && this.videoTrack.enabled
  }

  isAudioEnabled() {
    return this.audioTrack && this.audioTrack.enabled
  }

}

module.exports = StreamWrapper;
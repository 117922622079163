const RTCatLog = require('../utils/log')

function sequence(tasks, fn) {
	return tasks.reduce((promise, task) => {
		return promise.then(() => fn(task))
	}, Promise.resolve())
}

function compareResolution(resolution, capabilities) {
	if (resolution[0] > capabilities.width.max || resolution[0] < capabilities.width.min) {
		return false
	}
	if (resolution[1] > capabilities.height.max || resolution[1] < capabilities.height.min) {
		return false
	}
	if (resolution[2] && (resolution[2] > capabilities.frameRate.max || resolution[0] < capabilities.frameRate.min)) {
		return false
	}
	return true
}

/**
 * wrap browser device api
 * enumerateDevices : https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices
 */

async function _scanCamera(deviceId, resolutions) {
	let result = {
		support: [],
		unsupport: []
	}

	let capabilities

	let supportRatios = []

	let stream = undefined
	stream = await navigator.mediaDevices.getUserMedia({
		audio: false,
		video: {
			width: {
        ideal: 7680
      },
			height: {
        ideal: 4320
      },
			deviceId: deviceId
				? {
						exact: deviceId
				  }
				: undefined
		}
	})

	if (stream && stream.getVideoTracks && stream.getVideoTracks()[0] && stream.getVideoTracks()[0].getCapabilities) {
		capabilities = stream.getVideoTracks()[0].getCapabilities()
		stream.getTracks().forEach((track) => track.stop())
	}

	let processor = function (r) {
		RTCatLog.D(`check ${r[0]}x${r[1]}x${r[2]}`)

		return new Promise((resolve) => {
			let ratio = r[1] / r[0]
			if (capabilities) {
				if (compareResolution(r, capabilities)) {
					result.support.push(r)
					RTCatLog.D(`resolution ${r[0]}x${r[1]}x${r[2]} is supported `)
					resolve()
				} else {
          RTCatLog.D(`resolution ${r[0]}x${r[1]}x${r[2]} is not supported ?`);

					RTCatLog.W(error)
					result.unsupport.push(r)
					resolve()
				}
				return
			}
			if (supportRatios.includes(ratio)) {
				result.support.push(r)
				RTCatLog.D(`resolution ${r[0]}x${r[1]}x${r[2]} is supported `)
				resolve()
				return
			}

			navigator.mediaDevices
				.getUserMedia({
					audio: false,
					video: {
						width: {
							exact: r[0]
						},
						height: {
							exact: r[1]
						},
						frameRate: {
							exact: r[2]
						},
						deviceId: deviceId
							? {
									exact: deviceId
							  }
							: undefined
					}
				})
				.then((mediaStream) => {
					if (!supportRatios.includes(ratio)) {
						supportRatios.push(ratio)
					}
					RTCatLog.D(`resolution ${r[0]}x${r[1]}x${r[2]} is supported `)

					result.support.push(r)
					if (stream === undefined) {
						stream = mediaStream
					} else {
						mediaStream.getTracks().forEach((track) => track.stop())
					}
					resolve()
				})
				.catch((error) => {
					//  RTCatLog.D(`resolution ${r[0]}x${r[1]}x${r[2]} is not supported ?`);

					RTCatLog.W(error)
					result.unsupport.push(r)
					resolve()
				})
		})
	}

	return sequence(resolutions, processor).then(() => {
		if (stream) {
			stream.getTracks().forEach((track) => track.stop())
		}
		return result
	})
}

class Devices {
	static getCameras(cb) {
		return getDevices('videoinput', cb)
	}

	static getMics(cb) {
		return getDevices('audioinput', cb)
	}

	static getSpeakers(cb) {
		return getDevices('audiooutput', cb)
	}

	static getDevices(cb) {
		return getDevices('all', cb)
	}

	static async scanCamera(deviceId, resolutions) {
		if (Array.isArray(deviceId)) {
			resolutions = deviceId
			deviceId = null
		}

		if (!Array.isArray(resolutions)) {
			resolutions = [
				[1920, 1080, 25],
				[1280, 720, 25],
				[800, 600, 15],
				[640, 480, 15],
				[640, 360, 15],
				[352, 288, 15],
				[320, 240, 15],
				[176, 144, 15],
				[160, 120, 15]
			]
		} else {
			const sortCondition = (r1, r2) => {
				if (r1[0] > r2[0]) {
					return -1
				} else if (r1[0] === r2[0]) {
					if (r1[1] > r2[1]) {
						return -1
					} else if (r1[1] === r2[1]) {
						if (r1[2] >= r2[2]) {
							return -1
						} else {
							return 1
						}
					} else {
						return 1
					}
				} else {
					return 1
				}
			}

			resolutions.sort(sortCondition)
		}

		RTCatLog.D('check resolutions:', resolutions)

		let { support, unsupport } = await _scanCamera(deviceId, resolutions)

		/* if (unsupport.length > 0) {
      for (let r of unsupport) {
        const result = await _scanCamera(deviceId, [r]);
        support = support.concat(result.support);
      }
    } */

		if (unsupport.length > 0) {
			unsupport.forEach((r) => {
				RTCatLog.D(`resolution ${r[0]}x${r[1]}x${r[2]} is not supported`)
			})
		}

		return support
	}
}

function selectDevices(type, devices) {
	var devicesList = []
	let i = 0
	for (var device of devices) {
		if (device.kind == type || type == 'all') {
			let new_device = {}

			if (device.label === '') {
				//todo add warning
				i++
				if (device.kind == 'videoinput') {
					new_device.label = 'Camera-' + i
				} else if (device.kind == 'audioinput') {
					new_device.label = 'Mic-' + i
				} else if (device.kind == 'audiooutput') {
					new_device.label = 'Speaker-' + i
				} else {
					//todo add warning
					i--
					new_device.label = 'unknown device'
				}
			} else {
				new_device.label = device.label
			}

			new_device.groupId = device.groupId
			new_device.deviceId = device.deviceId
			new_device.kind = device.kind

			devicesList.push(new_device)
		}
	}
	return devicesList
}

function getDevices(type, callback) {
	if (!callback) {
		return new Promise((resolve, reject) => {
			navigator.mediaDevices
				.enumerateDevices()
				.then((devices) => {
					resolve(selectDevices(type, devices))
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

	navigator.mediaDevices
		.enumerateDevices()
		.then((devices) => {
			callback(null, selectDevices(type, devices))
		})
		.catch((error) => {
			callback(error, null)
		})
}
if (navigator && navigator.mediaDevices && navigator.mediaDevices.ondevicechange) {
	navigator.mediaDevices.ondevicechange = function () {
		RTCatLog.I('devices changed')
		if (Devices.ondevicechange && typeof Devices.ondevicechange === 'function') {
			Devices.ondevicechange()
		}
	}
}

module.exports = Devices

// === === === === test === === === === //

// navigator.mediaDevices.enumerateDevices().then((devices) => {
//   devices.forEach(device => {
//     console.log(device);
//     if (device.getCapabilities) {
//       console.log(device.getCapabilities())
//     }
//   })
// });

const RTCatLog = require('../utils/log');
const AbstractStream = require('./abstractstream');
const errors = require('./errors');
const CommandQueue = require('../command_queue/queue');

const FPS_DEFAULT = 15;
const STREAM_WIDTH_DEFAULT = 320;
const STREAM_HEIGHT_DEFAULT = 240;

const isChrome = !!navigator.webkitGetUserMedia

const VIDEO_CONSTRAINS =
    {
        qvga : { width: { ideal: 320 }, height: { ideal: 240 } },
        vga  : { width: { ideal: 640 }, height: { ideal: 480 } },
        hd   : { width: { ideal: 1280 }, height: { ideal: 720 } }
    };


/**
 * 本地流
 * https://www.w3.org/TR/mediacapture-streams/
 * https://w3c.github.io/mediacapture-screen-share/#dom-mediadevices-getdisplaymedia
 *
 * @extends AbstractStream
 */
class LocalStream extends AbstractStream {

  /**
   * @constructor
   * @param {external:Object} [options]
   * @param {RTCat.StreamType} [options.type=RTCat.StreamType.MediaDevice] 本地流的类型
   * @param {external:Object} [options.audio={(audioTrack, deviceId, enableEchoCancellation:true, enableNoiseSuppression:true, enableAutoGainControl:true) | constraints}]
   * @param {external:Object} [options.video={(videoTrack, deviceId, fps:15, size:{width:320, height:240}) | constraints}]
   * @param {external:Object} [options.external={(element, fps:10) | (src, muted:true, controls:true, loop:true, fps:10, syncGainVolume: false)}]
   *
   */
  constructor(options) {
    super({
      mediaStream: new MediaStream()
    });
    this._options = options;
    this._commandQueue = new CommandQueue();
  }

  /**
   * @fires LocalStream#accepted
   * @fires LocalStream#access-accepted
   * @returns {LocalStream}
   */
  async init() {

    await this.updateTracks(this._options);

    return this;
  }

  async updateTracks(options) {
    return this._commandQueue.push(() => {
      return this._updateTracks(options);
    })
  }

  async _updateTracks(options) {

    if (!options || typeof options !== 'object') {
      throw new Error('wrong options');
    }

    let {
      type = LocalStream.Type.MediaDevice,
        audio,
        video,
        external
    } = options;

    if (type !== this.type) {
      throw (new Error(`wrong type, should be ${this.type} instead of ${type}`));
    }

    switch (type) {

      case LocalStream.Type.MediaDevice: {
        let audioDisabled = this.audioTrack && !this.audioTrack.enabled
        if (audio && typeof audio === 'boolean') {
          audio = {};
        }

        if (video && typeof video === 'boolean') {
          video = {};
        }

        if (audio) {

          if (this.audioTrack) {
            this.audioTrack.stop();
          }

          if (audio.remove) {
            this._aConstraints = false;
          } else {
            this._initAudioConstraint(audio);
          }
        }

        if (video) {

          if (this.videoTrack) {
            this.videoTrack.stop();
          }

          if (video.remove) {
            this._vConstraints = false;
          } else {
            if (!video.constraints) {
              video.fps = video.fps || FPS_DEFAULT;

              video.size = video.size || {
                width: STREAM_WIDTH_DEFAULT,
                height: STREAM_HEIGHT_DEFAULT
              };
            }
            this._initVideoConstraint(video);
          }
        }

        if (audio && video) {

          const stream = await this.getUserMedia({
            audio: this._aConstraints,
            video: this._vConstraints
          });

          this.audioTrack = stream.getAudioTracks()[0];
          this.videoTrack = stream.getVideoTracks()[0];
          audioDisabled && (this.audioTrack.enabled = false);

        } else if (audio) {

          const stream = await this.getUserMedia({
            audio: this._aConstraints
          });

          this.audioTrack = stream.getAudioTracks()[0];
          audioDisabled && (this.audioTrack.enabled = false);

        } else if (video) {

          const stream = await this.getUserMedia({
            video: this._vConstraints
          });

          this.videoTrack = stream.getVideoTracks()[0];

        }

        break;
      }

      case LocalStream.Type.Screen: {

        if (this.audioTrack) {
          this.audioTrack.stop();
          this.audioTrack = null;
        }

        if (typeof video !== 'object') {
          video = {};
        }

        if (typeof audio !== 'object') {
          audio = audio !== false;
        }

        if (this.videoTrack) {
          this.videoTrack.stop();
        }

        // this._initVideoConstraint(video);

        const stream = await this.getDisplayMedia({
          audio: audio,
          regionShare: !!(external && external.regionShare)
        }
          // {
          // video: this._vConstraints
          // }
        );

        this.videoTrack = stream.getVideoTracks()[0];
        this.audioTrack = stream.getAudioTracks()[0];

        break;
      }

      case LocalStream.Type.SystemAudio: {
        if (this.audioTrack) {
          this.audioTrack.stop();
          this.audioTrack = null;
        }

        const stream = await this.getSystemAudio();
        this.audioTrack = stream.getAudioTracks()[0];

        break;
      }

      case LocalStream.Type.Custom: {
        if (video && video.videoTrack) {
          if (this.videoTrack) {
            this.videoTrack.stop()
          }
          this.videoTrack = video.videoTrack
          this._initVideoConstraint(video)
        }
        if (audio && audio.audioTrack) {
          if (this.audioTrack) {
            this.audioTrack.stop()
          }
          this.audioTrack = audio.audioTrack
          this._initAudioConstraint(audio)
        }

        break
      }

      case LocalStream.Type.HtmlElement: {

        if (!external || typeof external !== 'object' || !external.element) {
          throw (new Error('no htmlelement media source'));
        }

        let fps = external.fps || 10;
        let element = external.element;

        this._captureHtmlElement(element, fps, options && options.external && options.external.syncGainVolume, options && options.external && options.external.videoTrack);

        break;
      }

      case LocalStream.Type.File: {

        // https://html.spec.whatwg.org/multipage/media.html#htmlmediaelement
        // https://www.w3.org/TR/html50/embedded-content-0.html#htmlmediaelement

        if (!external || typeof external !== 'object' || !external.src) {
          throw (new Error('no file media source'));
        }

        if (this._fileVideoElement) {
          this._fileVideoElement.src = null;
        }

        const fileVideoElement = document.createElement('video');

        fileVideoElement.controls = external.controls !== undefined ? external.controls : true;
        fileVideoElement.muted = external.muted !== undefined ? external.muted : true;
        fileVideoElement.loop = external.loop !== undefined ? external.loop : true;
        fileVideoElement.setAttribute('playsinline', '');
        fileVideoElement.src = external.src;

        await fileVideoElement.play();

        this._fileVideoElement = fileVideoElement;

        let fps = external.fps || 10;
        let element = fileVideoElement;

        this._captureHtmlElement(element, fps, options && options.external && options.external.syncGainVolume, options && options.external && options.external.videoTrack);

        break;
      }

      default:
        throw (new Error(`type ${this.type} is not supported`));
    }

    this._options = options;

    this.emit('updateTracks', {
      audioTrack: this.audioTrack,
      videoTrack: this.videoTrack,
      stream: this,
      options
    });
  }

  /**
   * @param {MediaStreamTrack} track
   * @returns 
   */
  async replaceTrack(track) {
    return this._commandQueue.push(() => {
      return this._replaceTrack(track)
    })
  }

  async _replaceTrack(track) {
    if (track.kind === 'audio') {
      this.audioTrack = track
    } else if (track.kind === 'video') {
      this.videoTrack = track
    } else {
      throw new Error('track kind is not supported')
    }

    this.emit('updateTracks', {
      audioTrack: this.audioTrack,
      videoTrack: this.videoTrack,
      stream: this,
      options: this._options
    });
  }

  get type() {
    return this._options.type || LocalStream.Type.MediaDevice;
  }

  get srcElement() {

    let element = null;

    if (this.type === LocalStream.Type.HtmlElement) {
      element = this._options.external.element;
    } else if (this.type === LocalStream.Type.File) {
      element = this._fileVideoElement;
    }

    return element;
  }

  /**
   * 释放本地流资源,并回收播放器。
   */
  stop() {
    if (this._fileVideoElement) {
      this._fileVideoElement.src = null;
      this._fileVideoElement = null;
    }
    super.stop();
  }

  get typeName() {

    switch (this._type) {
      case LocalStream.Type.MediaDevice:
        return 'mediadevice';
      case LocalStream.Type.Screen:
        return 'screen';
      case LocalStream.Type.HtmlElement:
        return 'htmlelement';
      case LocalStream.Type.File:
        return 'file';
      case LocalStream.Type.SystemAudio:
        return 'systemaudio'
      case LocalStream.Type.Custom:
        return 'custom'
      default:
        return null;
    }

  }

  get trackSettings() {
    return {
      audio: this.audioTrack ? this.audioTrack.getSettings() : null,
      video: this.videoTrack ? this.videoTrack.getSettings() : null
    }
  }

  get videoResolution() {
    return { width : this._vConstraints ? this._vConstraints.width : null,
            height : this._vConstraints ? this._vConstraints.height : null};
    
  }

  async getUserMedia(constraints) {
    return await navigator.mediaDevices.getUserMedia(constraints);
  }

  async getDisplayMedia(constraints) {

    let weakSelf = this

    if ('getDisplayMedia' in window.navigator.mediaDevices) {
      // 设置屏幕分享是否分享音频
      return await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: constraints.audio,
        regionShare: constraints.regionShare
      });
    } else if ('getDisplayMedia' in window.navigator) {
      return await navigator.getDisplayMedia({
        video: true,
        audio: false
      })
    } else if (document.body.dataset.rtcExtensionId != null) {

      return new Promise((resolve, reject) => {

        const once = async (event) => {

          const {
            data: {
              type,
              streamId
            },
            origin
          } = event

          // NOTE: you should discard foreign events
          if (origin !== window.location.origin) {
            RTCatLog.W(
              'ScreenStream: you should discard foreign event from origin:',
              origin
            )
            // return;
          }

          // user chose a stream
          if (type === 'STREAM_SUCCESS') {

            window.removeEventListener('message', once)

            const mediaStream = await weakSelf.getUserMedia({
              audio: false,
              video: {
                mandatory: {
                  chromeMediaSource: 'desktop',
                  chromeMediaSourceId: streamId,
                  maxWidth: window.screen.width,
                  maxHeight: window.screen.height
                }
              }
            });

            resolve(mediaStream);

          } else if (type === 'STREAM_ERROR') {

            window.removeEventListener('message', once)

            reject(errors.capturestream_permission_error);

          }
        };

        window.addEventListener('message', once);

        window.postMessage({
          type: 'STREAM_REQUEST'
        }, '*');

      });

    } else {

      return await navigator.mediaDevices.getUserMedia({
        video: {
          mandatory: {
            chromeMediaSource: 'screen',
            maxWidth: window.screen.width,
            maxHeight: window.screen.height
          }
        }
      });
    }
  }

  async getSystemAudio() {
    if ('getDisplayMedia' in window.navigator.mediaDevices) {
      // 设置屏幕分享是否分享音频
      return await navigator.mediaDevices.getDisplayMedia({
        video: false,
        audio: true
      });
    }
  }

  _initAudioConstraint({
    deviceId,
    enableEchoCancellation = true,
    enableNoiseSuppression = true,
    enableAutoGainControl = true,
    constraints,
  }) {

    if (constraints) {
      this._aConstraints = constraints;
      return;
    }

    this._aConstraints = {
      optional: []
    }

    let advanced = [
      // {"googAudioMirroring":audioProcessed},
      {
        'googEchoCancellation': enableEchoCancellation
      },
      {
        'googAutoGainControl': enableAutoGainControl
      },
      {
        'googNoiseSuppression': enableNoiseSuppression
      },
      // {"googHighpassFilter":audioProcessed},
      // {"googNoiseSuppression2":audioProcessed},
      // {"googEchoCancellation2":audioProcessed},
      // {"googAutoGainControl2":audioProcessed},
    ];

    this._aConstraints.optional = this._aConstraints.optional.concat(advanced);

    if (deviceId) {
      this._aConstraints.optional.push({
        sourceId: deviceId
      });
    }
  }

  _initVideoConstraint({
    deviceId,
    fps,
    size,
    constraints,
  }) {

    if (constraints) {
      this._vConstraints = constraints
      return;
    }

    this._vConstraints = {};

    if (deviceId) {
      this._vConstraints.deviceId = {
        exact: deviceId
      }
    }

    if (fps) {
      this._vConstraints.frameRate = {
        ideal: fps
      };
    }

    if (size) {
      this._vConstraints.width = {
        ideal: size.width
      };

      this._vConstraints.height = {
        ideal: size.height
      };
    }
  }

  _captureHtmlElement(element, fps, syncGainVolume, videoTrack) {

    let stream = null;

    if (element.captureStream) {
      stream = element.captureStream(fps);
    } else if (element.mozCaptureStream) {
      // fix: firefox no audio
      stream = element.mozCaptureStream(fps);
    } else {
      if (isChrome) {
        throw errors.capturestream_should_enable_flag;
      } else {
        throw errors.capturestream_not_supported_yet
      }
    }

    if (stream) {
      // 尝试通过增益修改音量
      syncGainVolume && this._setupGainNode(stream, element);

      const oldAudioTrack = this.audioTrack;
      const oldVideoTrack = this.videoTrack;

      this.audioTrack = stream.getAudioTracks()[0];
      this.videoTrack = stream.getVideoTracks()[0];

      if (oldAudioTrack) {
        oldAudioTrack.stop();
      }

      if (oldVideoTrack) {
        oldVideoTrack.stop();
      }

    }

    // videoTrack 为外层处理过的，例如通过canvas重新生成track
    if (stream && videoTrack) {
      var oldVideoTrack = stream.getVideoTracks()[0]
      if (oldVideoTrack) {
        this.videoTrack = videoTrack
        stream.removeTrack(oldVideoTrack)
        stream.addTrack(videoTrack)
      }
    }
  }

  /**
   * 通过增益的方式修改媒体音量，推流端修改音量后，拉流端音量能同步变化
   * @param {*} stream 
   * @param {*} videoEl 
   */
  _setupGainNode(stream, videoEl) {
    // video 标签音量为1时，不需要通过增益修改音量
    if (!videoEl || videoEl.volume === 1) {
      return;
    }

    const audioTrack = stream.getAudioTracks()[0]
    var AudioContext = window.AudioContext || window.webkitAudioContext
    if (!AudioContext) {
      console.warn('AudioContext not surport!');
      return;
    }

    try {
      var ctx = new AudioContext();
      var src = ctx.createMediaStreamSource(new MediaStream([audioTrack]))
      var dst = ctx.createMediaStreamDestination()
      var gainNode = ctx.createGain()
      // 直接设置value 拉流端会出现爆音
      //gainNode.gain.value = videoEl && videoEl.volume || 0;
      gainNode.gain.setValueAtTime(videoEl && videoEl.volume || 0, ctx.currentTime);

      // Attach src -> gain -> dst
      ;[src, gainNode, dst].reduce((a, b) => a && a.connect(b))
      stream.removeTrack(audioTrack)
      var dstTrack = dst.stream.getAudioTracks()[0]
      stream.addTrack(dstTrack)
    }
    catch(e) {
      console.error('_setupGainNode exception ', e);
    }
    
  }

}

module.exports = LocalStream;
